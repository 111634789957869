export enum OperatorEnum {
  AND = 'AND',
  OR = 'OR',
}

export enum OperationEnum {
  EQ = 'EQ',
  IN = 'IN',
  GT = 'GT',
  LT = 'LT',
  GE = 'GE',
  LE = 'LE',
  LIKE = 'LIKE',
}
