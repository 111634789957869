<template>
  <div v-for="(level, levelIndex) in ['low', 'medium', 'high']" class="row mb-2" :key="level">
    <div class="flex xs12 lg4 pb-0">
      <money-input
        :label="$t(`property.form.resale_price.${level}.net`)"
        v-model="value.resalePrice[level].net"
        :id="propertyDetailsFormName(index, level).net"
        :name="propertyDetailsFormName(index, level).net"
        :min="min[level]"
        :max="max[level]"
        :squareMeter="true"
      />
    </div>
    <div class="flex xs12 lg4 pb-0" name="resale-section">
      <percentage-input
        :label="$t(`property.form.resale_price.${level}.agency_fees`)"
        v-model="value.resalePrice[level].agencyFees"
        :id="propertyDetailsFormName(index, level).agencyFees"
        :name="propertyDetailsFormName(index, level).agencyFees"
      />
    </div>
    <div class="flex xs12 lg4 pb-0">
      <computed-price
        :label="$t(`property.form.resale_price.${level}.computed_price`)"
        :id="propertyDetailsFormName(index, level).computedPrice"
        :name="propertyDetailsFormName(index, level).computedPrice"
        v-model="value.resalePrice[level].computedPrice"
        :size="value.size || size"
        :net="value.resalePrice[level].net"
        :agencyFees="value.resalePrice[level].agencyFees"
      />
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { useMutation } from '@vue/apollo-composable';
const toast = useToast();
import { useI18n } from 'vue-i18n';
import { reactive, ref, toRefs, watch } from 'vue';
import MoneyInput from '@/components/form/MoneyInput';
import PercentageInput from '@/components/form/PercentageInput';
import SizeInput from '@/components/form/SizeInput';
import useGetRules from '@/components/form/useGetRules';
import { PropertyTypeEnum } from '@mdb/core';
import { toNumber, defaultsDeep } from 'lodash';
import ComputedPrice from './ComputedPrice';
//import VueGoogleAutocomplete from 'vue-google-autocomplete'

// interface ResalePrice {
//   size: number;
//   resalePrice: {
//     low: { net: number, agencyFees: number, computedPrice: number },
//     medium: { net: number, agencyFees: '', computedPrice: number },
//     high: { net: number, agencyFees: '', computedPrice: number }
//   };
// }

export default {
  props: {
    index: { type: Number },
    size: { type: Number },
    value: { type: Object, default: false },
  },
  components: { MoneyInput, SizeInput, PercentageInput, ComputedPrice },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });

    const min = reactive({ low: null, medium: null, high: null });
    const max = reactive({ low: null, medium: null, high: null });
    const computedPrice = reactive({ low: null, medium: null, high: null });

    const propertyDetailsFormName = (index, level = '') => {
      const result = {
        net: `${index}-details-resale-price-${level}`,
        agencyFees: `${index}-details-resale-price-agency-fees-${level}`,
        computedPrice: `${index}-details-resale-price-computed-price-${level}`,
        sellingPrice: `${index}-details-resale-price-net`,
        size: `${index}-details-size`,
      };
      return result;
    };
    const getComputedPrice = (props) => {
      const { net, size, cutSize, agencyFees } = props;
      return {
        value: parseInt(cutSize | size) * parseInt(net) + parseInt(size) * parseInt(net) * (parseInt(agencyFees) / 100),
      };
    };
    watch(
      props.value,
      (value) => {
        min.low = 1000;
        min.medium = Boolean(value.resalePrice.low.net) ? parseInt(value.resalePrice.low.net) : null;
        min.high = Boolean(value.resalePrice.medium.net) ? parseInt(value.resalePrice.medium.net) : null;

        max.low = Boolean(value.resalePrice.medium.net) ? parseInt(value.resalePrice.medium.net) : null;
        max.medium = Boolean(value.resalePrice.high.net) ? parseInt(value.resalePrice.high.net) : null;
        max.high = null;

        // computedPrice.low = getComputedPrice({size: value.size, cutSize: value.resalePrice.size, net: value.resalePrice.low.net, agencyFees: value.resalePrice.low.agencyFees});
        // computedPrice.medium = getComputedPrice({size: value.size, cutSize: value.resalePrice.size, net: value.resalePrice.medium.net, agencyFees: value.resalePrice.medium.agencyFees});
        // computedPrice.high = getComputedPrice({size: value.size, cutSize: value.resalePrice.size, net: value.resalePrice.high.net, agencyFees: value.resalePrice.high.agencyFees});
      },
      { deep: true }
    );

    return {
      min,
      max,
      propertyDetailsFormName,
    };
  },
};
</script>

<style lang="scss"></style>
