<template>
  <p hidden>{{ data }}</p>
  <money-input :disabled="true" :label="label" v-model="data" :id="id" :name="name" :applyRules="false" />
</template>
<script>
import MoneyInput from '@/components/form/MoneyInput';
import { toRefs, ref, computed, watch } from 'vue';
import { formatMoney } from '@/components/helpers/Column';

export default {
  props: {
    net: { type: Number, required: true },
    agencyFees: { type: Number, required: true },
    size: { type: Number, required: true },
    name: { type: String, required: true },
    id: { type: String, required: true },
    label: { type: String, required: true }
  },
  components: { MoneyInput },
  setup(props) {
    const { net, agencyFees, size, value } = props;

    const data = ref();

    watch(
      props,
      newProps => {
        if (!newProps.size || !newProps.net || !newProps.agencyFees) {
          data.value = '';
        } else {
          data.value =
            parseInt(newProps.size) * parseInt(newProps.net) +
            parseInt(newProps.size) * parseInt(newProps.net) * (parseInt(newProps.agencyFees) / 100);
          data.value = data.value.toLocaleString();
        }
      },
      { deeps: true, immediate: true }
    );
    return { data, formatMoney };
  }
};
</script>
