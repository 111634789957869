<template>
  <money-input
    :id="id"
    :name="name"
    :label="label"
    :class="class"
    :min="min"
    :max="max"
    :title="title"
    :required="required"
    :applyRules="applyRules"
    :mask="mask"
    v-model="data"
    @update:model-value="handleInput"
  >
    <template #icon>
      <va-icon class="mr-2" name="euro_symbol" />
      <va-icon size="small" class="mr-2" v-if="squareMeter" style="line-height: initial">/</va-icon>
      <va-icon size="small" class="mr-2" v-if="squareMeter" style="line-height: initial">&#13217;</va-icon>
    </template>
  </money-input>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import useGetRules from './useGetRules.ts';
import MoneyInput from './NumberInput';
export default {
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    class: { type: String, default: '' },
    min: { type: Number, default: '' },
    max: { type: Number, default: '' },
    title: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    required: { type: Boolean, default: true },
    applyRules: { type: Boolean, default: true },
    squareMeter: { type: Boolean, default: false },
    mask: {
      type: Object,
      default: () => ({
        numeral: true,
        delimiter: ' ',
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        numeralDecimalScale: 0,
      }),
    },
  },
  components: { MoneyInput },
  setup(props, { emit }) {
    const data = ref();

    watch(
      () => props.modelValue,
      (value) => {
        data.value = value;
      },
      { immediate: true }
    );

    const handleInput = (value) => {
      emit('update:model-value', value);
    };
    return {
      data: data,
      handleInput,
    };
  },
};
</script>
