import gql from 'graphql-tag';

export const GET_USERS = gql`
  query users($requestedPage: Float, $limit: Float, $filter: FiltersExpression) {
    result: users(requestedPage: $requestedPage, limit: $limit, filter: $filter) {
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      hasMore
      prevPage
      nextPage
      docs {
        _id
        firstName
        lastName
        fullName
        phoneNumber
        email
        agency
        roles
        createdAt
        contacts {
          _id
          email
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: String!) {
    result: user(id: $id) {
      _id
      firstName
      lastName
      fullName
      phoneNumber
      email
      agency
      roles
      createdAt
      contacts {
        _id
        email
      }
    }
  }
`;

export const GET_ME = gql`
  query me {
    result: me {
      _id
      firstName
      lastName
      phoneNumber
      email
      agency
      roles
      createdAt
      contacts {
        _id
        email
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($data: CreateUserInput!) {
    result: createUser(data: $data) {
      _id
    }
  }
`;
export const LOGIN = gql`
  mutation login($data: LoginInput!) {
    result: login(data: $data)
  }
`;
export const SEND_EMAIL_RESET_PASSWORD = gql`
  mutation sendEmailResetPassword($email: String!) {
    result: sendEmailResetPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: UpdatePasswordInput!) {
    result: resetPassword(data: $data)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $data: UpdateUserInput!) {
    result: updateUser(id: $id, data: $data)
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    result: deleteUser(id: $id)
  }
`;
