<template>
  <money-input
    :id="id"
    :name="name"
    :label="label"
    :class="class"
    :min="min"
    :max="100"
    :title="title"
    :required="required"
    :applyRules="applyRules"
    :mask="mask"
    v-model="data"
    @update:model-value="handleInput"
  >
    <template #icon>
      <span>%</span>
    </template>
  </money-input>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import useGetRules from './useGetRules.ts';
import MoneyInput from './NumberInput';
export default {
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    class: { type: String, default: '' },
    min: { type: Number, default: '' },
    title: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    required: { type: Boolean, default: true },
    applyRules: { type: Boolean, default: true },
    mask: {
      type: Object,
      required: false
    }
  },
  components: { MoneyInput },
  setup(props, { emit }) {
    const data = ref(props.modelValue);
    const handleInput = value => {
      emit('update:model-value', value);
    };
    return {
      data: data,
      handleInput
    };
  }
};
</script>
