<template>
      <h1 class="va-page-title">
        {{pageTitle}}
      </h1>
</template>

<script>

export default {
   props: ['pageTitle'],
}
</script>

<style lang="scss">
  .va-page-title {
    padding-bottom: 2%;
  }
</style>
