<template>
  <va-input
    @update:model-value="handleInput"
    :class="class"
    :label="label"
    :id="id"
    :name="name"
    :mask="mask"
    :rules="[rules]"
    v-model="data"
  >
    <template #prependInner v-if="title">
      <va-icon name="info" size="small" :title="title" />
    </template>
    <template #appendInner>
      <slot name="icon"></slot>
    </template>
  </va-input>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import useGetRules from './useGetRules.ts';

export default {
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    class: { type: String, default: '' },
    min: { type: Number, default: '' },
    max: { type: Number, default: '' },
    title: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    required: { type: Boolean, default: true },
    applyRules: { type: Boolean, default: true },
    mask: {
      type: Object,
      default: () => ({
        numeral: true,
        delimiter: ' ',
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        numeralDecimalMark: ','
      })
    }
  },
  setup(props, { emit }) {
    const { min, max, required, modelValue } = toRefs(props);
    const { rules } = props.applyRules ? useGetRules({ min, max, required }) : [];
    const data = ref('');

    watch(
      () => props.modelValue,
      value => {
        if (!value) {
          data.value = '';
          return;
        }
        data.value = value.toLocaleString('fr-FR');
      },
      { immediate: true }
    );
    const handleInput = value => {
      emit('update:model-value', value);
    };
    return {
      data,
      rules,
      handleInput
    };
  }
};
</script>
