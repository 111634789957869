import gql from 'graphql-tag';

export const GET_PROPERTIES = gql`
  query properties($requestedPage: Float, $limit: Float, $filter: FiltersExpression) {
    result: properties(requestedPage: $requestedPage, limit: $limit, filter: $filter) {
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      hasMore
      prevPage
      nextPage
      docs {
        _id
        address {
          street
          additionalDetails
          postalCode
          city
          country
        }
        buyingPrice {
          net
          agencyFees
        }
        size
        type
        comment
        ground {
          size
          price {
            net
            agencyFees
          }
        }
        serviced
        sewage
        individualElectricMeter
        details {
          size
          rentAmount
          resalePrice {
            low {
              net
              agencyFees
              computedPrice
            }
            medium {
              net
              agencyFees
              computedPrice
            }
            high {
              net
              agencyFees
              computedPrice
            }
          }
        }
        createdAt
        createdBy {
          _id
          firstName
          lastName
          fullName
        }
        rentability
      }
    }
  }
`;

// export const GET_PROPERTIES_FULL_DATA = gql`
//   query properties($requestedPage: Float, $limit: Float, $filter: FiltersExpression) {
//     result: properties(requestedPage: $requestedPage, limit: $limit, filter: $filter) {
//         docs {_id}
//       }
//     }
//   }
// `;

export const GET_PROPERTY = gql`
  query property($id: String!) {
    result: property(id: $id) {
      _id
      address {
        street
        additionalDetails
        postalCode
        city
        country
      }
      buyingPrice {
        net
        agencyFees
      }
      size
      type
      comment
      ground {
        size
        price {
          net
          agencyFees
        }
      }
      serviced
      sewage
      individualElectricMeter
      details {
        rentAmount
        size
        resalePrice {
          low {
            net
            agencyFees
            computedPrice
          }
          medium {
            net
            agencyFees
            computedPrice
          }
          high {
            net
            agencyFees
            computedPrice
          }
        }
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
        fullName
      }
      rentability
    }
  }
`;

export const CREATE_PROPERTY = gql`
  mutation createProperty($data: CreatePropertyInput!) {
    result: createProperty(data: $data) {
      _id
    }
  }
`;

export const UPDATE_PROPERTY = gql`
  mutation updateProperty($id: String!, $data: UpdatePropertyInput!) {
    result: updateProperty(id: $id, data: $data)
  }
`;

export const DELETE_PROPERTY = gql`
  mutation deleteProperty($id: String!) {
    result: deleteProperty(id: $id)
  }
`;
