<template>
  <va-inner-loading :loading="loading | readOnly" color="primary" :icon="readOnly ? '' : 'autorenew'">
    <va-form ref="form" name="section-address">
      <div>
        <va-input
          class="mb-2"
          :label="$t('property.form.address')"
          v-model="data.address.street"
          :rules="[useGetRules({ required: true }).rules]"
          id="street"
          name="street"
        />
        <va-input
          class="mb-2"
          :label="$t('property.form.additional_address_details')"
          v-model="data.address.additionalDetails"
          id="additional-details"
          name="additional-details"
        />
        <div class="row">
          <div class="flex xs12 md6 pt-0">
            <div class="item">
              <va-input
                :label="$t('property.form.postal_code')"
                v-model="data.address.postalCode"
                id="postal-code"
                name="postal-code"
                :rules="[useGetRules({ required: true }).rules]"
              />
            </div>
          </div>
          <div class="flex xs12 md6 pt-0">
            <div class="item">
              <va-input
                :label="$t('property.form.city')"
                v-model="data.address.city"
                id="city"
                name="city"
                :rules="[useGetRules({ required: true }).rules]"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4" name="price-and-size-section">
        <div class="row">
          <div class="flex xs12 md6 pb-0">
            <money-input
              class="mb-2"
              :label="$t('property.form.buying_price.net')"
              v-model="data.buyingPrice.net"
              id="buying-price-net"
              name="buying-price-net"
            />
          </div>
          <div class="flex xs12 md6 pb-0">
            <percentage-input
              class="mb-2"
              :label="$t('property.form.buying_price.agency_fees')"
              v-model="data.buyingPrice.agencyFees"
              id="buying-agency-fees"
              name="buying-agency-fees"
            />
          </div>
        </div>
        <size-input
          class="mb-2"
          :label="$t('property.form.total_size')"
          v-model="data.size"
          id="size"
          name="size"
          :min="1"
          :rules="[useGetRules({ required: true }).rules]"
        />
      </div>
      <div class="mb-3" name="property-type-section">
        <div class="mb-2 ml-1">
          <label>{{ $t('property.form.select_operation') }}</label>
        </div>
        <div>
          <va-chip
            :class="dataTypeClass(propertyType.key)"
            v-for="(propertyType, index) in propertyTypes"
            :key="propertyType.key"
            :id="propertyType.key"
            :style="readOnly ? { opacity: 'none' } : ''"
            @click="setPropertyType(propertyType.key)"
          >
            {{ propertyType.label }}
          </va-chip>
        </div>
      </div>
      <div v-if="data.type">
        <div v-if="data.type == PropertyTypeEnum.HOUSE" class="mb-2" name="house-section">
          <div id="has-ground">
            <va-switch
              color="secondary"
              v-model="data.hasGround"
              :label="$t('property.form.has_ground')"
              left-label
              id="has-ground"
              :true-inner-label="$t('common.yes')"
              :false-inner-label="$t('common.no')"
            />
          </div>
          <div v-if="data.hasGround">
            <size-input
              class="mb-2"
              :label="$t('property.form.ground.size')"
              v-model="data.ground.size"
              :min="1"
              :required="data.hasGround"
              id="ground-size"
              name="ground-size"
            />
            <money-input
              class="mb-2"
              :label="$t('property.form.ground.price')"
              v-model="data.ground.price.net"
              :required="data.hasGround"
              id="ground-price"
              name="ground-price"
            />
            <money-input
              class="mb-2"
              :label="$t('property.form.ground.agency_fees')"
              v-model="data.ground.price.agencyFees"
              :required="data.hasGround"
              id="ground-agency-fees"
              name="ground-agency-fees"
            />
          </div>
        </div>
        <div v-if="data.type == PropertyTypeEnum.LAND" name="land-section">
          <div class="mb-1" id="is-serviced">
            <va-switch
              color="secondary"
              v-model="data.serviced"
              :label="$t('property.form.serviced')"
              left-label
              :true-inner-label="$t('common.yes')"
              :false-inner-label="$t('common.no')"
              id="is-serviced"
            />
          </div>
          <div id="is-sewage">
            <va-switch
              color="secondary"
              v-model="data.sewage"
              :label="$t('property.form.sewage')"
              left-label
              id="is-sewage"
              :true-inner-label="$t('common.yes')"
              :false-inner-label="$t('common.no')"
            />
          </div>
        </div>
        <div class="mb-3" v-if="data.type == PropertyTypeEnum.CUT" name="cut-section">
          <div id="has-individual-electric-meter">
            <va-switch
              color="secondary"
              class="mb-2"
              :label="$t('property.form.individual_electric_meter')"
              v-model="data.individualElectricMeter"
              :true-inner-label="$t('common.yes')"
              :false-inner-label="$t('common.no')"
              left-label
            />
          </div>
          <div id="cut-number" class="mt-2">
            <va-slider v-model="data.cutNumber" track-label-visible mix="1" max="25">
              <template #label>
                <div style="">
                  {{ $t('property.form.cut_number') }}
                </div>
              </template>
            </va-slider>
          </div>
        </div>
        <div v-for="(n, index) in cutNumber()" :key="index" class="property-details" name="property-details-section">
          <div class="mb-3" v-if="data.type === PropertyTypeEnum.CUT" name="property-details-cut-section">
            <va-divider class="mb-3">
              <span class="px-2 divider-title">{{ $t('property.form.cut') + ' ' + (index + 1) }}</span>
            </va-divider>
            <size-input
              :label="$t('property.form.size')"
              v-model="data.details[index].size"
              :id="propertyDetailsFormName(index).size"
              :name="propertyDetailsFormName(index).size"
              :min="1"
            />
          </div>
          <div id="rented-section" class="mb-3">
            <div id="is-rented">
              <va-switch
                color="secondary"
                v-model="data.details[index].isRented"
                :label="$t('property.form.is_rented')"
                left-label
                class="ml-1 mb-2"
                :true-inner-label="$t('common.yes')"
                :false-inner-label="$t('common.no')"
              />
            </div>
            <money-input
              class="mb-4"
              v-if="data.details[index].isRented"
              :label="$t('property.form.rent_amount')"
              v-model="data.details[index].rentAmount"
              id="rent-amount"
              name="rent-amount"
              :required="data.details[index].isRented"
            />
          </div>
          <div class="mb-3" name="resale-agency-fees-section">
            <resale-price :size="data.size" :index="index" :value="data.details[index]" />
          </div>
        </div>
        <va-input
          class="mb-3 mt-1"
          v-model="data.comment"
          type="textarea"
          :label="$t('property.form.comment')"
          :min-rows="1"
          :max-rows="5"
          id="comment"
          name="comment"
        />
      </div>
      <va-button size="large" data-cy="submit-button" @click="handleSubmit" color="secondary" :disabled="!data.type">{{
        $t('form.submit')
      }}</va-button>
    </va-form>
  </va-inner-loading>
</template>

<script>
import { useToast } from 'vue-toastification';
import { useMutation } from '@vue/apollo-composable';
const toast = useToast();
import { useI18n } from 'vue-i18n';
import { reactive, ref, toRefs, watch } from 'vue';
import MoneyInput from '@/components/form/MoneyInput';
import PercentageInput from '@/components/form/PercentageInput';
import SizeInput from '@/components/form/SizeInput';
import useGetRules from '@/components/form/useGetRules';
import { PropertyTypeEnum } from '@mdb/core';
import { toNumber, defaultsDeep, isEmpty } from 'lodash';
import ComputedPrice from './ComputedPrice';
import ResalePrice from './ResalePrice';
import SimpleToast from '@/components/helpers/SimpleToast';

//import VueGoogleAutocomplete from 'vue-google-autocomplete'
const initialDetailsData = () => ({
  size: null,
  isRented: false,
  rentAmount: null,
  resalePrice: {
    low: { net: '', agencyFees: '', computedPrice: '' },
    medium: { net: '', agencyFees: '', computedPrice: '' },
    high: { net: '', agencyFees: '', computedPrice: '' },
  },
});
export default {
  props: {
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    submitAction: { type: Function, required: false },
    initialData: {
      type: Object,
      default: () => ({
        address: {
          street: '',
          additionalDetails: '',
          postalCode: '',
          city: '',
        },
        size: null,
        buyingPrice: { net: null, agencyFees: null },
        hasGround: false,
        ground: { size: '', price: { net: '', agencyFees: '' } },
        type: '',
        comment: '',
        cutNumber: 1,
        // land
        serviced: false,
        // land
        sewage: false,
        // cut
        individualElectricMeter: false,
        details: [initialDetailsData()],
      }),
    },
  },
  components: { MoneyInput, SizeInput, PercentageInput, ComputedPrice, ResalePrice },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });

    const form = ref('propertyForm');

    // define form
    const data = reactive({
      ...props.initialData,
      details: props.initialData.details.map((detail) => ({ ...detail, isRented: Boolean(detail.rentAmount) })),
      hasGround: Boolean(props.initialData.ground && props.initialData.ground.size),
    });

    watch(
      () => props.initialData,
      (newProps) => {
        // if (!newProps) {
        //   return;
        // }
        // const dataNew = {
        //   ...newProps,
        //   buyingPrice: {
        //     net: '75 000',
        //     agencyFees: newProps.buyingPrice.agencyFees
        //   }
        // };
        // data.value = { ...dataNew };
      },
      { deep: true, immediate: true }
    );

    const dataTypeClass = (key) => {
      let style = 'mr-4 mb-2 ';
      if (props.readOnly) {
        style += 'read-only';
      }
      if (data.type === key) {
        style += ' clicked';
      }
      return style;
    };

    const propertyTypes = Object.keys(PropertyTypeEnum)
      .filter((elt) => isNaN(PropertyTypeEnum[elt]))
      .map((elt) => ({
        key: PropertyTypeEnum[elt],
        label: t(`property_type.${PropertyTypeEnum[elt]}`),
      }));
    const setPropertyType = (propertyType) => {
      data.type = propertyType;
    };
    const propertyDetailsFormName = (index, level = '') => {
      const result = {
        net: `${index}-details-resale-price-${level}`,
        agencyFees: `${index}-details-resale-price-agency-fees-${level}`,
        computedPrice: `${index}-details-resale-price-computed-price-${level}`,
        sellingPrice: `${index}-details-resale-price-net`,
        size: `${index}-details-size`,
      };
      return result;
    };

    const resalePriceData = (index) => ({
      low: data.details[index - 1].resalePrice.low,
      medium: data.details[index - 1].resalePrice.medium,
      high: data.details[index - 1].resalePrice.high,
    });

    watch(
      () => data.cutNumber,
      (value) => {
        if (value) {
          let newPropertyDetails = [...data.details];
          if (data.details.length < value) {
            const numberToAdd = value - data.details.length;
            for (let i = 0; i < numberToAdd; i++) {
              newPropertyDetails.push(initialDetailsData());
            }
          } else {
            const numberToRemove = data.details.length - value;
            for (let i = 0; i < numberToRemove; i++) {
              newPropertyDetails.pop();
            }
          }
          data.details = newPropertyDetails;
        }
      }
    );

    watch(
      () => data.cutNumber,
      (value, oldValue) => {
        if (!value) {
          data.cutNumber = oldValue;
        }
        //Validation();
      }
    );
    const cutNumber = () => (data.type !== PropertyTypeEnum.CUT ? 1 : data.cutNumber);

    const isValid = () => {
      const isValidDetail = (detail) => {
        if (detail.isRented && isEmpty(detail.rentAmount)) {
          return false;
        }
        if (isEmpty(detail.resalePrice.low.net) || isEmpty(detail.resalePrice.low.agencyFees)) {
          return false;
        }
        if (isEmpty(detail.resalePrice.medium.net) || isEmpty(detail.resalePrice.medium.agencyFees)) {
          return false;
        }
        if (isEmpty(detail.resalePrice.high.net) || isEmpty(detail.resalePrice.high.agencyFees)) {
          return false;
        }
        if (
          !Boolean(
            parseInt(detail.resalePrice.medium.net) > parseInt(detail.resalePrice.low.net) &&
              parseInt(detail.resalePrice.medium.net) < parseInt(detail.resalePrice.high.net)
          )
        ) {
          return false;
        }
        return true;
      };
      if (
        isEmpty(data.address.street) ||
        isEmpty(data.address.postalCode) ||
        isEmpty(data.address.city) ||
        isEmpty(data.size) ||
        isEmpty(data.buyingPrice.net) ||
        isEmpty(data.buyingPrice.agencyFees) ||
        isEmpty(data.type)
      ) {
        return false;
      }
      if (
        data.type === PropertyTypeEnum.HOUSE &&
        data.hasGround &&
        (isEmpty(data.ground.size) || isEmpty(data.ground.price.net) || isEmpty(data.ground.price.agencyFees))
      ) {
        return false;
      }
      for (let i = 0; i < cutNumber(); i++) {
        if (!isValidDetail(data.details[i])) {
          return false;
        }
      }
      return true;
    };

    const formatData = () => {
      const object = {
        address: data.address,
        serviced: data.serviced,
        // land
        sewage: data.sewage,
        // cut
        individualElectricMeter: data.individualElectricMeter,
        type: data.type,
        size: parseInt(data.size),
        buyingPrice: {
          net: parseInt(data.buyingPrice.net),
          agencyFees: parseInt(data.buyingPrice.agencyFees),
        },
        ground: null,
        comment: data.comment,
      };
      if (data.hasGround && data.type === PropertyTypeEnum.HOUSE) {
        object.ground = {
          size: parseInt(data.ground.size),
          price: {
            net: parseInt(data.ground.price.net),
            agencyFees: parseInt(data.ground.price.agencyFees),
          },
        };
      }

      object.details = [];
      data.details.slice(0, cutNumber()).forEach((detail, index) => {
        object.details.push({
          rentAmount: data.details[index].isRented ? parseInt(data.details[index].rentAmount) : null,
          size: data.details[index].size ? parseInt(data.details[index].size) : object.size,
          resalePrice: {
            low: {
              net: parseInt(data.details[index].resalePrice.low.net),
              agencyFees: parseInt(data.details[index].resalePrice.low.agencyFees),
              computedPrice: parseInt(data.details[index].resalePrice.low.computedPrice),
            },
            medium: {
              net: parseInt(data.details[index].resalePrice.medium.net),
              agencyFees: parseInt(data.details[index].resalePrice.low.agencyFees),
              computedPrice: parseInt(data.details[index].resalePrice.low.computedPrice),
            },
            high: {
              net: parseInt(data.details[index].resalePrice.high.net),
              agencyFees: parseInt(data.details[index].resalePrice.high.agencyFees),
              computedPrice: parseInt(data.details[index].resalePrice.high.computedPrice),
            },
          },
        });
      });
      return object;
    };

    const handleSubmit = () => {
      if (isValid()) {
        const object = formatData();
        props.submitAction(object);
      } else {
        form.value.validate();
        toast(
          {
            component: SimpleToast,
            props: {
              text: t('form.error.generic_message.completion'),
              id: 'error-toast',
            },
          },
          {
            timeout: 2000,
            type: 'error',
            id: 'error-toast',
          }
        );
      }
    };

    const getDetailsData = () => {
      if (data.type == PropertyTypeEnum.CUT) {
        return data.details;
      }
      return [data.details[0]];
    };

    const getAddressData = (addressData, placeResultData, id) => {
      console.log(addressData);
    };

    return {
      data,
      form,
      handleSubmit,
      propertyTypes,
      PropertyTypeEnum,
      propertyDetailsFormName,
      setPropertyType,
      getAddressData,
      useGetRules,
      getDetailsData,
      dataTypeClass,
      cutNumber,
    };
  },
};
</script>

<style lang="scss">
.property-details {
  padding-top: 10px;
  padding-bottom: 10px;
}
.clicked {
  opacity: 0.5;
}
.divider-title {
  font-size: 0.925rem;
  letter-spacing: 0.6px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.8;
}

.va-input__label {
  height: 12px;
  max-width: var(--va-input-container-label-max-width);
  color: rgb(21, 78, 193);
  font-size: var(--va-input-container-label-font-size);
  letter-spacing: var(--va-input-container-label-letter-spacing, var(--va-letter-spacing));
  line-height: var(--va-input-container-label-line-height);
  font-weight: var(--va-input-container-label-font-weight);
  text-transform: var(--va-input-container-label-text-transform);
  transform-origin: top left;
}
.read-only {
  &:hover {
    opacity: inherit;
  }
}
</style>
